<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Control de Rubros</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Formulario de Datos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field label="Nombre" v-model="name"> </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="Descripción" v-model="description">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-switch label="Estado del Rubro" v-model="status"></v-switch>
              </v-col>
              <v-col cols="6" md="6">
                <v-btn block color="error" @click="cleanData()" rounded
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6" md="6">
                <v-btn
                  block
                  v-if="statusData == 1"
                  color="success"
                  @click="addData()"
                  rounded
                  >Agregar</v-btn
                >
                <v-btn
                  block
                  v-if="statusData == 2"
                  color="success"
                  @click="updateData()"
                  rounded
                  >Actualizar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            Lista de Rubros
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsRubros"
            :search="search"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template></v-data-table
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Rubros",
  data: () => ({
    search: "",
    itemsRubros: [],
    id: "",
    name: "",
    description: "",
    status: true,
    statusData: 1,

    headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Descripción", value: "description" },
      { text: "Acciones", value: "actions" },
    ],
  }),
  mounted() {
    this.loadRubros();
  },
  methods: {
    loadRubros() {
      axios.get(process.env.VUE_APP_API_URL + "rubros").then((result) => {
        this.itemsRubros = result.data;
      });
    },
    editItem(id) {
      axios.get(process.env.VUE_APP_API_URL + "rubros/" + id).then((result) => {
        this.id = result.data[0].id;
        this.name = result.data[0].name;
        this.description = result.data[0].description;
        this.status = result.data[0].status;
        this.statusData = 2;
      });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "rubros", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRubros();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "rubros/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRubros();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el rubro no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "rubros/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rubro eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadRubros();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El rubro no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
